<template>
    
      
      <div id="maintext">    
        <Home msg="Hi! I am Nishchal Nepal"/>
      </div>
    
    
    
</template>

<script>
import Home from './components/Home.vue'

export default {
  name: 'App',
  components: {
    Home
  }
}

</script>

<style>
#maintext {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #0e0c0c;
  margin-top: 20px;  
}
</style>
