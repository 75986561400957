<template>
  <div class="home">   
    <img id=mainimage src = "../assets/Main.jpg">  
    <h1>{{ msg }}</h1>
    <p>
      The website is currently under maintenance. Please visit back at a later date.      
    </p>
    
  </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#mainimage {
  border-radius: 50%;
  padding: 0;  
  width: 500px;  
}
</style>
