import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'

Vue.use(VueRouter);

const routes=[
{
  path: '/',
  name: 'Home',
  component: App
}];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')